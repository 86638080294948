import { SlideNumber, configureSplideI18n } from './slide-func.js';

document.addEventListener("DOMContentLoaded", function () {
  $(function () {
    configureSplideI18n();

    if ($(".calendarSlider").length > 0) {
      const splideInstance = new Splide(".calendarSlider", {
        perPage: 1,
        pagination: false,
        arrows: false,
        gap: "20px",
        breakpoints: {
          1199: {
            perPage: 1,
          },
          767: {
            perPage: 1,
            pagination: false,
          },
          479: {
            perPage: 1,
            pagination: false,
            padding: {
              /*  right: 80, */
            },
          },
        },
      }).mount({
        SlideNumber,
      });

      // Wybierz nagłówek
      const sliderTopDate = $('#sliderTopDate');

      // Funkcja aktualizująca tekst nagłówka na podstawie aktywnego slajdu
      function updateTopData(date) {
        // Funkcja do sformatowania dzisiejszej daty
        function getFormattedDate(date) {
          const day = new Date(date);
          const options = { year: 'numeric', month: 'long', day: 'numeric' };
          return day.toLocaleDateString('pl-PL', options); // Używa formatu polskiego
        }

        if (typeof(date) != "undefined" && date !== null) {
          sliderTopDate.html(getFormattedDate(date));
          sliderTopDate.closest('time').attr('datetime', date);
        }
      }

      // Dodaje events to kustomowych przycisków
      const btnCalendarNext = document.querySelector('.calendarSlider__next'); // nowy przytisk nest
      const btnCalendarPrev = document.querySelector('.calendarSlider__prev'); // nowy przytisk prev

      btnCalendarNext.addEventListener('click', e => {
        splideInstance.go('+1');
      });

      btnCalendarPrev.addEventListener('click', e => {
        splideInstance.go('-1');
      });

      // ładuje poprzedni slide po załadowaniu strony
      if (splideInstance.state.is(Splide.STATES.IDLE)) {
        btnCalendarNext.style.visibility = "hidden";
        if (splideInstance.index === 0) {
          let date = getChangedCalendarDate( -1);
          loadCalendarEvents(date, 'prev');
        }
      }

      // ukrywa strzałkę w prawo przy dzisiejszej dacie
      splideInstance.on('move', function () {
        let currentSlide = splideInstance.Components.Slides.getAt(splideInstance.index).slide;
        let currentSlideDate = $(currentSlide).children('.calendarDate').html();
        let today = formatDate(new Date());

        if (currentSlideDate === today) {
          btnCalendarNext.style.visibility = "hidden";
        } else {
          btnCalendarNext.style.visibility = "visible";
        }
      });

      splideInstance.on('moved', function (newIndex, prevIndex) {
        // aktualizacja daty
        let currentSlide = splideInstance.Components.Slides.getAt(splideInstance.index).slide;
        let currentSlideDate = $(currentSlide).children('.calendarDate').html();
        updateTopData(currentSlideDate);

        // ładuje poprzedni slide po załadowaniu pierwszego slajdu
        if (newIndex === 0) {
          let daysChange = newIndex - prevIndex;
          let previousSlideDate = getChangedCalendarDate(daysChange);

          loadCalendarEvents(previousSlideDate, 'prev');
        }
      });

      // zwraca date zmienioną o podaną liczbę dni w formacie YYY-MM-DD
      function getChangedCalendarDate(days) {
        let calendarDate = sliderTopDate.closest('time').attr('datetime');
        let date = new Date(calendarDate);
        date.setDate(date.getDate() + days);
        return formatDate(date);
      }

      function formatDate(date) {
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = ('0' + date.getDate()).slice(-2);

        return [year, month, day].join('-');
      }

      function loadCalendarEvents(date, direction) {

        $.get('Ajax/get_calendar', { date: date }, function(data){
          let itemsHtml = '';
          itemsHtml += '<li class="splide__slide item">';
          itemsHtml += '<span hidden class="calendarDate">' + data.date + '</span>';
          itemsHtml += '<ul class="calendarSlider__oneDay">';

          if(data.items.length === 0) {
            itemsHtml += '<li class="calendarSlider__oneDayItem"><p class="title">Brak wydarzeń na dany dzień</p></li>';

          } else {
            data.items.forEach(function (item) {
              itemsHtml += '<li class="calendarSlider__oneDayItem">';
              itemsHtml += '<p class="title">' + item.summary + '</p>';
              itemsHtml += '</li>';
            });
          }

          itemsHtml += '</ul>';
          itemsHtml += '</li>';

          let index = direction === 'next' ? splideInstance.index + 1 : direction === 'prev' ? 0 : splideInstance.index;

          splideInstance.add(itemsHtml, index);
          if (splideInstance.index === 0 && direction === 'prev') {
            splideInstance.Components.Controller.setIndex(1);
            splideInstance.refresh();
          }
        });
      }

    }
  });
});
